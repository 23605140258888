import { Game, GameFeedback, GameFeedbackChannel, WebHook } from "@dragonsight/models";
import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Icon, LinearProgress, Paper, Stack, Switch, Tab, TablePagination, Tabs, ToggleButton, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TextInput } from "../../components/form";
import Page from "../../components/page";
import { AddAlert } from "../../shared/alert";
import { Api } from "../../shared/api";

import DeleteIcon from '@mui/icons-material/DeleteForever';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Section from "../../components/section";
import { FeedbackItems } from "./gameFeedback";
import ContentContainer from "../../components/contentContainer";

const publicApi = process.env.REACT_APP_PUBLIC_API_ENDPOINT;

interface GameValues {
    name: string;
    description?: string;
    notes?: string;
    twitchId?: string;
    youtubeId?: string;
}

const UpdateDialog = (props: {
    show: boolean,
    game: Game,
    onClose: () => void,
    onGameAdded: (game: Game) => void,
}) => {
    const [ submitting, setSubmitting ] = useState(false);
    const { game, show, onClose, onGameAdded } = props;

    const AddGame = async (values: GameValues) => {
        setSubmitting(true);
        try {
            const response = await Api.GameUpdate({
                id: game.id,
                ...values
            });
            AddAlert({
                severity: "success",
                message: `${response.name} has been updated.`
            })
            onGameAdded(response);
            onClose();
        } catch(e:any) {
            AddAlert({
                severity: "error",
                message: e.message,
            })
        } finally {
            setSubmitting(false);
        }
    }

    return <Dialog open={show} onClose={() => onClose()}>
    <DialogTitle>Update game</DialogTitle>
        <Formik initialValues={{ name: game.name, description: game.description, notes: game.notes, twitchId: game.twitchId }}
            onSubmit={(values) => AddGame(values)}>
            {({
                handleSubmit, values
            }) => (<>
                <DialogContent>
                    <DialogContentText>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ marginBottom: 4 }}>
                                    <TextInput
                                        label="name"
                                        name="name"
                                        type="text"
                                        disabled={submitting}
                                        required
                                        />
                                </Grid>

                                <Grid item xs={12} sx={{ marginBottom: 4 }}>
                                    <TextInput
                                        label="Description"
                                        name="description"
                                        type="text"
                                        disabled={submitting}
                                        />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Notes"
                                        name="notes"
                                        type="text"
                                        disabled={submitting}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="TwitchId"
                                        name="twitchId"
                                        type="text"
                                        disabled={submitting}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()}>Cancel</Button>
                    <Button onClick={() => handleSubmit()}>Update</Button>
                </DialogActions>
            </>)}
        </Formik>
    </Dialog>
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`game-tabpanel-${index}`}
        aria-labelledby={`game-tab-${index}`}
        {...other}
      >
        {value === index && (
            <div>{children}</div>
        )}
      </div>
    );
  }

const GamePage = () => {
    const navigate = useNavigate();
    const { gameId } = useParams();
    const [loading, setLoading] = useState(false);
    const [ game, setGame] = useState<Game | undefined>(undefined);
    const [ showUpdate, setShowUpdate ] = useState(false);
    const [ tab, setTab] = useState(0);
    const [ webhooks, setWebhooks ] = useState<WebHook[]>([]);


    useEffect(() => {
        const call = async () => {
            try {
                setLoading(true);
                const response = await Api.GameRead(gameId!);
                setGame(response);
                const webhookResponse = await Api.Webhook.List("", undefined, undefined);
                setWebhooks(webhookResponse.items);
            } catch(e: any) {
                AddAlert({ severity: "error", message: "Unable to get game."})
                navigate("/game");
            } finally {
                setLoading(false);
            }
        }
        call();
    }, [])

    if(loading || game === undefined){
        return <LinearProgress></LinearProgress>
    }

    const removeGame = async () => {
        try {
            await Api.GameDelete(gameId!);
            AddAlert({ severity: "success", message: `${game.name} has been removed from your account.`})
            navigate("/game");
        } catch(e: any) {
            AddAlert({ severity: "error", message: `${game.name} was not able to be deleted.`})
        } finally {

        }
    };

    

    function a11yProps(index: number) {
        return {
          id: `game-tab-${index}`,
          'aria-controls': `game-tabpanel-${index}`,
        };
      }

    return <Page title={game.name} breadcrumbs={[
        { label: "Home", to: "/"},
        { label: "Game", to: "/game"},
        { label: game.name }
    ]}>
        <UpdateDialog show={showUpdate} game={game} onClose={() => setShowUpdate(false)} onGameAdded={(game) => setGame(game)} />
        
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(event, value) => setTab(value)} aria-label="Game Page tabs">
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Feedback" {...a11yProps(1)} />
            <Tab label="Streams" {...a11yProps(2)} />
            <Tab label="Settings" {...a11yProps(3)} />
        </Tabs>
        </Box>
        <CustomTabPanel value={tab} index={0}>
            <ContentContainer>
                <Section header="Game Details">
                    <Grid container spacing={1}>
                        <Grid item md={3} xs={12}><Typography fontSize={12}>Description</Typography></Grid>
                        <Grid item md={9} xs={12}>{game.description}</Grid>
                        <Grid item md={3} xs={12}><Typography fontSize={12}>Notes</Typography></Grid>
                        <Grid item md={9} xs={12}>{game.notes}</Grid>
                    </Grid>
                    
                    <Stack spacing={1} marginTop={2} direction="row">
                        <Button variant="contained" onClick={() => setShowUpdate(true)}>Update</Button>
                        <Button variant="contained" onClick={() => removeGame()}>Remove game</Button>
                    </Stack>
                </Section>
            </ContentContainer>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
            <FeedbackItems />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={2}>
            Currently not implemented
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={3}>
            <ContentContainer>
                <Section header="Notifications">
                    <Typography>Your account notifcation platforms are listed below, you can add this game to them so when notifcations about the game get sent out this is the places it sends to.</Typography>
                    <Stack marginTop={2} spacing={1}>
                        {webhooks.map((webhook) => {
                            return <WebhookItem webhook={webhook} game={game} onChange={() => undefined} />
                        })}
                    </Stack>
                </Section>
            </ContentContainer>
        </CustomTabPanel>
    </Page>
}

const WebhookItem = (props: { 
    webhook: WebHook, 
    game: Game,
    onChange: (webhook: WebHook) => void;
}) => {
    const { webhook, game } = props;
    const [ submitting, setSubmitting ] = useState(false);

    const addGameNotification = async (webhook: WebHook) => {
        setSubmitting(true);
        try {
            await Api.Webhook.AddGame(webhook.id, game.id!);

            AddAlert({ severity: "success", message: `${game.name} notifications on ${webhook.name} have been updated.`});
        } catch( e: any) {
            AddAlert({ severity: "error", message: `Updating notifications failed`});
        } finally {
            setSubmitting(false);
        }
    }

    const removeGameNotification = async (webhook: WebHook) => {
        setSubmitting(true);
        try {
            await Api.Webhook.RemoveGame(webhook.id, game.id!);
            AddAlert({ severity: "success", message: `${game.name} notifications on ${webhook.name} have been removed.`});
        } catch( e: any) {
            AddAlert({ severity: "error", message: `Remove notifications failed`});
        } finally {
            setSubmitting(false);
        }
    }

    
    const isActive = webhook.hooks.some(wh => wh.gameId === game.id);

    if(submitting){
        return <div><CircularProgress /></div>
    }

    return <div key={webhook.id}>
        {webhook.name} - {isActive ? <Button onClick={() => removeGameNotification(webhook)}>Remove</Button> : <Button onClick={() => addGameNotification(webhook)}>Add</Button>}
    </div>
}

const ListItem = (props: {}) => {

}

export default GamePage;
