import { TextFieldOptions } from '@aws-amplify/ui-react';
import { TextField, TextFieldProps } from '@mui/material';
import { Formik, Form, useField } from 'formik';

export interface TextInputProps {
    type?: string;
    name: string;
    label?: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    autocomplete?: string;
    required?: boolean;
    helperText?: string;
    placeholder?: string;
    fullwidth?: boolean;
    variant?: "standard" | "filled" | "outlined"
}

const TextInput = (props: TextInputProps) => {
    const { disabled, label } = props;
    const [field, meta] = useField(props.name);
    return <>
        <TextField
            required={props.required}
            fullWidth={props.fullwidth ?? true}
            variant={props.variant}
            disabled={disabled}
            label={label}
            type={props.type}
            autoComplete={props.autocomplete}
            helperText={meta.error ?? props.helperText}
            error={(meta.touched && (meta.error !== undefined))}
            placeholder={props.placeholder}
            { ...field }
        />
    </>
}

export default TextInput;