import { Box, Button, CircularProgress, Grid, Link, Paper, Tab, Tabs, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Page from "../../components/page";
import { AddAlert } from "../../shared/alert";
import { Api } from "../../shared/api";
import { AppContext } from "../../shared/appContext";
import { Formik, FormikHelpers } from "formik";
import { Auth } from "aws-amplify";
import { TextInput } from "../../components/form";

interface PasswordReset {
    current: string;
    password: string;
    passwordRepeat: string;
}

const AccountPage = () => {
    const [tab, setTab] = useState(1);
    const { account } = useContext(AppContext);
    const [manageUrl, setManageUrl] = useState<undefined | string>();

    const [submittingPassword, setSubmittingPassword] = useState(false);
    const context = useContext(AppContext);

    const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const onSubmitPasswordChange = async (values: {current: string, password: string, passwordRepeat: string}, helper: FormikHelpers<PasswordReset>) => {
        setSubmittingPassword(true);

        if(values.password !== values.passwordRepeat) {
            AddAlert({
                severity: "error",
                message: "Passwords don't match"
              })
            return;
        }

        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(user, values.current, values.password);
            helper.resetForm()
            AddAlert({
                severity: "success",
                message: "Password changed."
            })
        } catch(e: any){
            AddAlert({
                severity: "error",
                message: "Unable to change password, check current password is correct."
            })
        }
        finally {
            setSubmittingPassword(false);
        }
    }

    useEffect(() => {
        const getManageUrl = async () => {
            try {
                const response = await Api.BillingPortal();
                setManageUrl(response.url);
            } catch (e: any) {
                AddAlert({
                    message: e.message,
                    severity: "error"
                });
            }
        }

        getManageUrl();
    }, [])

    return <Page title="Account">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={onTabChange} aria-label="basic tabs example">
                <Tab value={1} label="Billing"/>
                <Tab value={2} label="Information"/>
                <Tab value={3} label="Password"/>
            </Tabs>
        </Box>
        <Paper>
            <Box sx={{ p: 2}}>
            {tab === 1 && <>
                <Box sx={{ mb:3}}>
                    <Typography variant="h5" sx={{ mb: 2}}>Subscription Details</Typography>    
                    <Typography variant="body2">
                        Current licences: {account.licences}
                    </Typography>
                    <Typography variant="body2">
                        Renewal date: {account.stripeSubscriptionEndDate ? new Date(account.stripeSubscriptionEndDate * 1000).toDateString() : 'N/A'}
                    </Typography>
                </Box>
                <Box sx={{ mb:2}}>
                    <Typography variant="h5" sx={{ mb: 2}}>Subscription options</Typography>
                    
                    { manageUrl !== undefined ? <>
                        <Typography variant="caption">Click this link to manage your subscription through the stripe platform.</Typography>
                        <div>
                            <Link href={manageUrl}>Manage Subscription</Link>
                        </div>
                    </> : <><CircularProgress /></> }
                </Box>
            </>}
            {tab === 2 && <>
                <Typography variant="h5">Account Name:</Typography>
                {account.name}
                <Typography variant="h5">Users:</Typography>
                <Typography variant="body2">User management coming soon.</Typography>
            </>}
            {tab === 3 && <>
                <Typography component="h1" variant="h5">
                    Change Password
                </Typography>
                <Formik<PasswordReset> initialValues={{ current: "", password: "", passwordRepeat: "" }}
                    onSubmit={(values, h) => onSubmitPasswordChange(values, h)}>
                    {({
                    handleSubmit, resetForm
                    }) => (
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput
                            label="Current password"
                            name="current"
                            type="password"
                            disabled={submittingPassword}
                            required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextInput
                            label="New password"
                            name="password"
                            type="password"
                            disabled={submittingPassword}
                            required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextInput
                            label="Repeat password"
                            name="passwordRepeat"
                            type="password"
                            disabled={submittingPassword}
                            required
                            />
                        </Grid>
                    </Grid>
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={submittingPassword}
                    sx={{ mt: 3, mb: 2 }}
                    >
                    Change
                    </Button>
                </Box>
                )}
                </Formik>
            </>}
            </Box>
        </Paper>
    </Page>;
};

export default AccountPage;
