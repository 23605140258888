import { Dialog, DialogTitle } from "@mui/material"
import { useState } from "react";
import { Api } from "../../shared/api";
import { Lead } from "@dragonsight/models";
import { LeadForm, LeadFormValues } from "./leadForm";

const AddLeadDialog = (props: {show: boolean, onClose: () => void, onNewLeadAdded: (value: Lead) => void}) => {
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async (values: LeadFormValues) => {
        setSubmitting(true);

        try {
            const newLead = await Api.Crm.Add({
                name: values.name!,
                company: values.company!,
                note: values.notes,
                platforms: values.platforms,
                contacts: values.contacts,
                messages: [],
            });
            props.onNewLeadAdded(newLead);
            props.onClose();
        } catch (error: any) {

        }
        finally {
            setSubmitting(false);
        }
    }

    

    return <Dialog open={props.show} onClose={props.onClose}>
        <DialogTitle>Add Lead</DialogTitle>
        <LeadForm onSubmit={(values) => handleSubmit(values)} onCancel={props.onClose} submitting={submitting} />
    </Dialog>
}

export default AddLeadDialog