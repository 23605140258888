import { Avatar, Box, Button, Checkbox, FormControlLabel, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import * as Auth from '../../shared/auth';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { TextInput } from '../../components/form';
import { AddAlert } from '../../shared/alert';
import { CognitoUser } from 'amazon-cognito-identity-js';

const SignInPage = (props: {
  onSwitchToSignUp: () => void,
  onSwitchToForgot: () => void,
  setUsername: (username: string) => void,
  onUserSignedIn: (user: CognitoUser) => void,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [pageState, setpageState] = useState<"signIn" | "newPassword">("signIn");
    const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>(undefined);

    const onSubmit = async (values: {email: string, password: string}) => {
      setSubmitting(true);

      try {
        props.setUsername(values.email);
        const user = await Auth.signIn(values.email, values.password);
        console.log(user);
        if(user.challengeName === "NEW_PASSWORD_REQUIRED"){
          setpageState("newPassword");
          setCognitoUser(user);
          AddAlert({
            severity: "info",
            message: "Password reset required."
          })
          setSubmitting(false);
        }else{
          props.onUserSignedIn(user);
        }
      } catch(e: any){
        setSubmitting(false);
      }
      finally {
          
      }
    }

    const onSubmitNewPassword = async(values: {password: string}) => {
      setSubmitting(true);

      try {
        await Auth.completeNewPassword(cognitoUser, values.password);
      } catch(e: any) {
        AddAlert({ severity: "error", message: e.message});
      } finally {
        setSubmitting(false);
      }
    }

    const signInContent = () => {
      return <Formik initialValues={{ email: "", password: ""}}
        onSubmit={(values) => onSubmit(values)}>
        {({
        handleSubmit,
      }) => (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                label="Email Address"
                name="email"
                type="email"
                disabled={submitting}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Password"
                name="password"
                type="password"
                disabled={submitting}
              />
            </Grid>
          </Grid>
          { submitting && <LinearProgress sx={{ mt: 2, mb: 2}} /> }
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={submitting}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button type='button' variant='text' onClick={() => props.onSwitchToSignUp()}>
                Don't have an account? Sign up
              </Button>
              <Button type='button' variant='text' onClick={() => props.onSwitchToForgot()}>
                Forgot password
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      </Formik>
    }

    const newPasswordContent = () => {
      return <Formik initialValues={{ password: ""}}
        onSubmit={(values) => onSubmitNewPassword(values)}>
        {({
        handleSubmit,
      }) => (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                label="Password"
                name="password"
                type="password"
                disabled={submitting}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={submitting}
            sx={{ mt: 3, mb: 2 }}
          >
            Set Password
          </Button>
        </Box>
      )}
      </Formik>
    }

    return <>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {pageState === "signIn" && signInContent()}
        </Box>
    </>
}

export default SignInPage;
