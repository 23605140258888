import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal } from "@mui/material";
import React from "react";
import { ReactNode, useContext, useRef, useState } from "react";


type ConfirmModalProps = {
    children: ReactNode;
    }
    
interface ConfirmProps {
    title: ReactNode;
    message: ReactNode;
    okLabel?: string;
    cancelLabel?: string;
}

type ModalContextType = {
    confirm: (data: ConfirmProps) => Promise<boolean>,
};
        
const ConfirmationModalContext = React.createContext<ModalContextType>({} as ModalContextType);

export const ConfirmModalProvider =(props: ConfirmModalProps) => {
    const { children } = props;
    const [ show, setShow ] = useState(false);
    const [ content, setContent ] = useState<ConfirmProps | undefined>(undefined);
    const resolver = useRef<Function>();

    const confirm = (data: ConfirmProps): Promise<boolean> => {
        setShow(true);
        setContent({
            ...data,
            okLabel: data.okLabel ?? "OK",
            cancelLabel: data.cancelLabel ?? "Cancel"
        })
        return new Promise(function (resolve) {
            resolver.current = resolve;
        })
    }

    const handleOk = () => {
        resolver.current && resolver.current(true);
        setShow(false);
    };

    const handleCancel = () => {
        resolver.current && resolver.current(false);
        setShow(false)
    };

    return <ConfirmationModalContext.Provider value={{ confirm }}>
        {children}
        { content && <Dialog open={show} onClose={handleCancel}>
            <DialogTitle>{content.title}</DialogTitle>
            <DialogContent>{content.message}</DialogContent>
            <DialogActions>
                <Button onClick={handleOk}>{content.okLabel}</Button>
                <Button onClick={handleCancel}>{content.cancelLabel}</Button>
            </DialogActions>
        </Dialog> }
    </ConfirmationModalContext.Provider>
}
export const useConfirmModal = (): ModalContextType => useContext(ConfirmationModalContext);