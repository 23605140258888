import { Avatar, Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import * as Auth from '../../shared/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { TextInput } from '../../components/form';
import { AddAlert } from '../../shared/alert';

interface ResetPasswordPageProps {
  user: string;
  onComplete: () => void;
}

const ResetPasswordPage = (props: ResetPasswordPageProps) => {
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async (values: {code: string, password: string}) => {
      setSubmitting(true);

      try {
          await Auth.forgotPasswordSubmit(props.user, values.code, values.password);
          props.onComplete();
          AddAlert({
            severity: "success",
            message: "Password reset, please log back in."
          })
      } catch(e: any){
          AddAlert({
            severity: "error",
            message: e.meesage
          })
      }
      finally {
          setSubmitting(false);
      }
    }

    return <>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset password
          </Typography>
          <Formik initialValues={{ code: "", password: "" }}
              onSubmit={(values) => onSubmit(values)}>
              {({
              handleSubmit,
            }) => (
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextInput
                  label="Confirm code"
                  name="code"
                  type="text"
                  disabled={submitting}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextInput
                  label="New password"
                  name="password"
                  type="password"
                  disabled={submitting}
                  required
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={submitting}
              sx={{ mt: 3, mb: 2 }}
            >
              Reset password
            </Button>
          </Box>
        )}
          </Formik>
        </Box>
    </>
}

export default ResetPasswordPage;