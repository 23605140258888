import { Account } from "@dragonsight/models";
import { createContext } from "react";

export interface User {
    username: string;
}

interface AppState {
    user: User;
    account: Account;
    changeAccount: (id: string) => void;
    signout: () => void
}

let currentAccount: Account;

export const setCurrentAccount = (account: Account) => {
    currentAccount = account;
}

export const getCurrentAccount = (): Account => {
    return currentAccount;
}

export const AppContext = createContext<AppState>({
    user: {username: ""},
    account: {
        id: "",
        licences:0,
        name: "",
        userId: ""
    },
    signout: () => {},
    changeAccount: () => {} });
