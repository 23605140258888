import { Alert, AlertColor } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { useEffect, useState } from "react";
import {v4} from "uuid";

interface AlertInstance {
    id: string,
    severity: AlertColor,
    message: string,
}
let addAlert: undefined | ((alert: AlertInstance) => void);

export function AddAlert(alert: Omit<AlertInstance, "id">) {
    const newAlert = { ...alert, id: v4() };
    if(addAlert !== undefined){
        addAlert(newAlert);
    }
}

const AlertBar = () => {
    const [open, setOpen] = useState(false);
    const [alerts, setAlerts] = useState<readonly AlertInstance[]>([]);
    const [active, setActive] = useState<AlertInstance | undefined>(undefined);

    useEffect(() => {
        addAlert = (alert) => {
            setAlerts((prev) => [ ...prev, alert]);
        }
        return () => {
            addAlert = undefined;
        }
    }, []);

    useEffect(() => {
        if (alerts.length && !active) {
            // Set a new alert when we don't have an active one
            setActive({ ...alerts[0] });
            setAlerts((prev) => prev.slice(1));
            setOpen(true);
          } else if (alerts.length && active && open) {
            // Close an active alert when a new one is added
            setOpen(false);
          }
    }, [alerts, open, active])

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };

    const handleExited = () => {
        setActive(undefined);
    }

    return <>
        <Snackbar
            open={open}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center"}}
            TransitionProps={{ onExited: handleExited }}
            onClose={handleClose}
        >
            <Alert severity={active?.severity} onClose={handleClose}>{active?.message}</Alert>
        </Snackbar>
    </>
}

export default AlertBar;