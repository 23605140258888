import { Amplify, Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

const _handleAuthResponse = Amplify.Auth._handleAuthResponse.bind(Auth)

Amplify.Auth._handleAuthResponse = (url: any) => {
    const configuration = Auth.configure()
    //@ts-ignore
    if (!(url as string).includes(configuration!.oauth!.redirectSignIn))
    {
      return;
    }
    return _handleAuthResponse(url)
  }

Amplify.configure({
    Auth: {
        region: 'ap-southeast-2',
        userPoolId: process.env.REACT_APP_USERPOOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEBCLIENT_ID,
        mandatorySignIn: false,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        oauth: {
            domain: process.env.REACT_APP_USERPOOL_DOMAIN,
            scope: ['email'],
            redirectSignIn: `${process.env.APP_ENDPOINT}/bot`,
            redirectSignOut: `${process.env.APP_ENDPOINT}/bot`,
            responseType: 'code'
        },

    },
    API: {
        endpoints: [
            {
                name: "DragonSightApi",
                endpoint: process.env.REACT_APP_API_ENDPOINT,
                custom_header: async () => {
                    //return { Authorization: 'token' };
                    // Alternatively, with Cognito User Pools use this:
                    //return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                  }
            }
        ]
    }
  });

export const signUp = async (email: string, password: string,
    attributes: {
        firstName: string,
        middleName?: string,
        lastName: string,
        marketing: boolean,
    } ) => {
    const response = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
            given_name: attributes.firstName,
            middle_name: attributes.middleName,
            family_name: attributes.lastName,
            'custom:marketing': attributes.marketing ? 'true' : 'false'
        },
        autoSignIn: {
            enabled: true,
        }
    });
    return response;
}

export const forgotPassword = async(email: string) => {
    const response = await Auth.forgotPassword(email);
    return response;
}

export const forgotPasswordSubmit = async(email: string, code: string, password: string) => {
    const response = await Auth.forgotPasswordSubmit(email, code, password);
    return response;
}

export const resendConfirmationCode = async (email: string) => {
    await Auth.resendSignUp(email);
}

export const confirmSignUp = async (email: string, code: string) => {
    await Auth.confirmSignUp(email, code);
}

export const signIn = async (email: string, password: string): Promise<CognitoUser | any> => {
    return await Auth.signIn(email, password);
}

export const completeNewPassword = async (user: any, password: string): Promise<any> => {
    return await Auth.completeNewPassword(user, password);
}

export const singOut = async () => {
    await Auth.signOut();
}
