import { Box, Button, DialogContent, Grid, IconButton, Typography } from "@mui/material"
import { FieldArray, Formik, FormikErrors } from "formik";
import { TextInput } from "../../components/form";
import { Lead, LeadContact } from "@dragonsight/models";
import { Clear } from "@mui/icons-material";

export interface LeadFormValues {
    name?: string,
    company?: string,
    notes?: string,
    platforms: string[],
    contacts: LeadContact[],
}

export const LeadForm = (props: {
        lead?: Lead,
        onSubmit: (values: LeadFormValues) => void,
        onCancel: () => void,
        submitting: boolean}
        ) => {
    const { onSubmit, submitting, lead} = props;

    const validate = (values: LeadFormValues): FormikErrors<LeadFormValues> => {
        const errors: FormikErrors<LeadFormValues> = {};
        if((values.name?.length ?? 0) < 2) errors.name = "Too short";
        return errors;
    }

    return <Formik<LeadFormValues>
        initialValues={{
            company: lead?.company,
            name: lead?.name,
            notes: lead?.note,
            platforms: lead?.platforms ?? [],
            contacts: lead?.contacts ?? [],
        }}
        onSubmit={(values) => onSubmit(values)}
        validate={validate}
        >
            {({handleSubmit, values}) => (
                <DialogContent>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ marginBottom: 4 }}>
                            <TextInput
                                label="Name"
                                name="name"
                                type="text"
                                disabled={submitting}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: 4 }}>
                            <TextInput
                                label="Company"
                                name="company"
                                type="text"
                                disabled={submitting}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: 4 }}>
                        <FieldArray name="contacts">
                                {({ insert, remove, push}) => (
                                    <>
                                        <Typography>Contacts</Typography>
                                        <div>
                                            {values.contacts.map((contact, index) => {
                                                return <Grid container spacing={1} sx={{ marginTop: 1}}>
                                                    <Grid item xs={3}>
                                                        <TextInput
                                                            key={index}
                                                            name={`contacts[${index}].type`}
                                                            type="text"
                                                            disabled={submitting}
                                                            placeholder="Email/Twitter"
                                                            variant="standard"
                                                            />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <TextInput
                                                            key={index}
                                                            name={`contacts[${index}].username`}
                                                            type="text"
                                                            disabled={submitting}
                                                            placeholder="gameti@hello.com"
                                                            variant="standard"
                                                            />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton color="primary" onClick={() => remove(index)}><Clear /></IconButton>
                                                    </Grid>
                                                </Grid>
                                            })}
                                        </div>
                                        <Button
                                            fullWidth
                                            type="button"
                                            onClick={() => push({type: "", username: ""})}
                                        >Add Contact</Button>
                                    </>
                                )}
                                
                            </FieldArray>
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: 4 }}>
                            <FieldArray name="platforms">
                                {({ insert, remove, push}) => (
                                    <>
                                        <Typography>Platforms</Typography>
                                        <div>
                                            {values.platforms.map((platform, index) => {
                                                return <Grid container spacing={1} sx={{ marginTop: 1}}>
                                                    <Grid item xs={11}>
                                                        <TextInput
                                                            key={index}
                                                            name={`platforms[${index}]`}
                                                            type="text"
                                                            disabled={submitting}
                                                            placeholder="Platform Url"

                                                            variant="standard"
                                                            />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton color="primary" onClick={() => remove(index)}><Clear /></IconButton>
                                                    </Grid>
                                                </Grid>
                                            })}
                                        </div>
                                        <Button
                                            fullWidth
                                            type="button"
                                            onClick={() => push("")}
                                        >Add Platform</Button>
                                    </>
                                )}
                                
                            </FieldArray>
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: 4 }}>
                            <TextInput
                                label="Notes"
                                name="notes"
                                type="text"
                                disabled={submitting}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={submitting}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {lead ? "Save" : "Add"}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                disabled={submitting}
                                sx={{ mt: 3, mb: 2 }}
                                onClick={() => props.onCancel()}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                </DialogContent>
            )}
    </Formik>
}