import { Paper } from "@mui/material"
import { ReactNode } from "react"

export interface ContentContainerProps {
    children?: ReactNode;
}

const ContentContainer = (props: ContentContainerProps) => {
    return <Paper sx={{ mt: 2, p: 3 }}>
        {props.children}
    </Paper>
}

export default ContentContainer;