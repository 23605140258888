import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import { Box, Card, CardActions, CardContent, CardHeader, Container, Grid, LinearProgress, Link, Typography } from "@mui/material/";
import Page from "../../components/page";
import { Api } from "../../shared/api";
import { AppContext } from "../../shared/appContext";
import { WebHook, WebHookCreateRequest } from "@dragonsight/models";
import Button from "@mui/material/Button";
import { AddAlert } from "../../shared/alert";
import { token } from "../../shared/theme";
import { Formik } from "formik";
import TextInput from "../../components/form/textInput";

const WebhookRow = (props: {webhook: WebHook}) => {
    return <div>
        {props.webhook.name} : {props.webhook.url}
    </div>
}

interface WebhookFormProps {
    onCreate: (webhook: WebHook) => void;
}

const WebhookForm = (props: WebhookFormProps) => {
    const [ submitting, setSubmitting ] = useState(false);

    const handleFormSubmit = async (values: WebHookCreateRequest) => {
        setSubmitting(true);
        try {
            const response = await Api.Webhook.Add(values);

            props.onCreate(response);
        } catch (e) {

        } finally {
            setSubmitting(false);
        }
    }
    return <Box marginTop={token.spacing.s400}>
        <Typography fontSize={24}>Add a new webhook</Typography>
        {/* <Typography fontSize={token.font.size.body}>
            A feedback channel a specific stream of feedback items, you may use this for a demo or different versions of your game.
            Once you create a channel you can post from your game using the game-id and channel-id to record feedback directly in your game.
        </Typography> */}
        <Formik<WebHookCreateRequest> initialValues={{name: "", type:"DISCORD", url: "" }} onSubmit={(values) => handleFormSubmit(values)}>
        {({
            handleSubmit, values
        }) => (<>
            <TextInput
                label="Name"
                name="name"
                type="text"
                disabled={submitting}
            />
            <TextInput
                label="Url"
                name="url"
                type="text"
                disabled={submitting}
            />
            <Button disabled={submitting} onClick={() => handleSubmit()}>Add Webhook</Button>
        </>)}
        </Formik>
    </Box>
}

export const IntegrationsPage = () => {
    const navigate = useNavigate();
    const userContext = useContext(AppContext);

    const [ loading, setLoading ] = useState(false);
    const [ webhooks, setWebhooks ] = useState<WebHook[]>([]);

    const handleListWebhooks = async () => {
        setLoading(true);
        try {
            const response = await Api.Webhook.List("");
            setWebhooks(response.items);
        } catch (e) {
            AddAlert({
                message: "Could not load webhooks",
                severity: "error"
            })
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        handleListWebhooks();
    }, []);

    const content = () => {
        return <>
            {webhooks.map(wh => <WebhookRow webhook={wh} />)}
        </>
    }

    return <Page title="Integrations" breadcrumbs={[
        { label: "Home", to: "/"},
        { label: "Integrations" }
    ]}>
        {loading ? "Loading.." : <>
                {content()}
                <WebhookForm onCreate={() => undefined} />
            </>
        }
    </Page>
}