import { AppBar, Toolbar, Typography, Link, LinkProps, Menu, MenuItem, Button, Avatar, IconButton, Badge } from "@mui/material";


import PersonIcon from '@mui/icons-material/Person';
import { User, AppContext } from "../../shared/appContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Menu as MenuIcon } from '@mui/icons-material';

import logo from '../../logo.png';

interface HeaderProps {
    sections: ReadonlyArray<{
        title: string;
        url: string;
      }>;
}

const HeaderLink = (props: LinkProps) => {
    const navigate = useNavigate();
    return <Link
        {...props}
        onClick={(event)=> {
            props.onClick?.(event);
            if(!event.defaultPrevented && props.href !== undefined){
                event.preventDefault();
                navigate(props.href);
            }
        }}
    >{props.children}</Link>
}

const Header = (props: HeaderProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const { sections } = props;

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    return <AppContext.Consumer>
        {( app ) => { return <>
        <AppBar
            position="static"
            color="primary"
            elevation={0}
            sx={{
                boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.1)"
            }}
        >
        <Toolbar >
            <Avatar src={logo} /> 
            <Typography
            component="h2"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ ml: 1, flexGrow: 1 }}
            >
                DragonSight
            </Typography>
            <nav>
            {sections.map((section) => (
                <HeaderLink
                    href={section.url}
                    key={section.title}
                    color="inherit"
                    noWrap
                    variant="body1"
                    sx={{ my: 1, mx: 1.5 }}
                    underline="none"
                    >{section.title}
                </HeaderLink>
            ))}
            </nav>
            <IconButton color="inherit" onClick={(event) => { handleOpenMenu(event)} }>
                <Badge badgeContent={0} color="secondary">
                    <MenuIcon />
                </Badge>
            </IconButton>

        </Toolbar></AppBar>
        <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
        >
            <MenuItem onClick={() => { app.signout() }}>Sign out</MenuItem>
        </Menu>
        </>}}
    </AppContext.Consumer>
}

export default Header;
