import { Checkbox, FormControlLabel } from '@mui/material';
import { useField } from 'formik';

export interface CheckboxInputProps {
    name: string;
    label: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
}

const CheckboxInput = (props: CheckboxInputProps) => {
    const { disabled, label } = props;
    const [field, meta] = useField(props.name);
    return <FormControlLabel
        control={<Checkbox
            disabled={disabled}
            color="primary"
            {...field}

            />}
        label={label}
    />
}

export default CheckboxInput;