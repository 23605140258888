import { Avatar, Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { Formik, FormikErrors } from 'formik';
import { useState } from 'react';
import * as Auth from '../../shared/auth';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { TextInput } from '../../components/form';
import { AddAlert } from '../../shared/alert';
import CheckboxInput from '../../components/form/checkbox';

interface SignUpPageProps {
  onSwitchToSignIn: () => void;
}

interface FormValues {
  email: string,
  password: string,
  passwordRepeat: string,
  accountName: string,
  firstName: string,
  lastName: string,
  marketing: boolean,
}

const SignUpPage = (props: SignUpPageProps) => {
    const [submitting, setSubmitting] = useState(false);

    const onValidate = (values: FormValues): FormikErrors<FormValues> => {
      const errors: FormikErrors<FormValues> = {};

      if(values.password !== values.passwordRepeat) {
        errors.passwordRepeat = "Password must match"
      }

      if(values.firstName.length == 0) {
        errors.firstName = "Required";
      }

      if(values.lastName.length == 0) {
        errors.lastName = "Required";
      }

      return errors;
    }

    const onSubmit = async (values: FormValues) => {
      setSubmitting(true);

      try {
          const response = await Auth.signUp(values.email, values.password, {
            firstName: values.firstName,
            lastName: values.lastName,
            marketing: values.marketing,
          });
      } catch(e: any){
          AddAlert({ severity: "error", message: e.message });
      }
      finally {
          setSubmitting(false);
      }
    }

    return <>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Formik
            initialValues={{ email: "", password: "", passwordRepeat: "", accountName: "", firstName: "", lastName: "", marketing: false }}
              onSubmit={(values) => onSubmit(values)}
              validate={(values) => onValidate(values)}
              >
              {({
              handleSubmit,
            }) => (
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput
                  label="Email Address"
                  name="email"
                  type="email"
                  disabled={submitting}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label="Password"
                  name="password"
                  type="password"
                  disabled={submitting}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label="Repeat Password"
                  name="passwordRepeat"
                  type="password"
                  disabled={submitting}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label="First Name"
                  name="firstName"
                  type="text"
                  disabled={submitting}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label="Last Name"
                  name="lastName"
                  type="text"
                  disabled={submitting}
                  required
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextInput
                  label="Account Name"
                  name="accountName"
                  type="text"
                  disabled={submitting}
                />
              </Grid> */}
              <Grid item xs={12}>
                <CheckboxInput disabled={submitting} name="martketing" label="I want to receive marketing promotions and updates via email." />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={submitting}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button type='button' variant='text' onClick={() => props.onSwitchToSignIn()}>
                  Already have an account? Sign in
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
          </Formik>
        </Box>
    </>
}

export default SignUpPage;