import { GameFeedback, GameFeedbackChannel } from "@dragonsight/models";
import { Box, Button, Chip, CircularProgress, FormControlLabel, Grid, Paper, Stack, Switch, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TextInput } from "../../components/form";
import { AddAlert } from "../../shared/alert";
import { Api } from "../../shared/api";
import { styled } from "@mui/material";

import CopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Section from "../../components/section";
import { token } from "../../shared/theme";
import ContentContainer from "../../components/contentContainer";
import { useConfirmModal } from "../../components/confirmModal";
import { title } from "process";

const publicApi = process.env.REACT_APP_PUBLIC_API_ENDPOINT;

const FeedbackChannel = (props: {channel: GameFeedbackChannel, onRemove: (channelId: string) => void}) => {
    const confirm = useConfirmModal();
    const { channel, onRemove } = props;
    const { gameId } = useParams();
    const [ loading, setLoading ] = useState(false);

    const feedbackUrl = `${publicApi}${channel.id}`;

    const removeFeedbackChannel = async (channel: GameFeedbackChannel) => {
        try {
            const willDelete = await confirm.confirm({
                message: "Are you sure you want to delete this feedback channel.",
                title: `Delete ${channel.name}`
            });

            if(willDelete){
                setLoading(true);
                await Api.Game.FeedbackChanelRemove(gameId!, channel.id);
                onRemove(channel.id)
            }
        } catch (e) {
            AddAlert({
                message: "Unable to delete feedback channel.",
                severity:  "error"
            })
        } finally {
            setLoading(false);
        }
    }

    const copyToClipboard = async (text: string) => {
        await navigator.clipboard.writeText(text);
      };

    const jsonObect = JSON.stringify({
        channeld: channel.id,
        gameId: gameId,
        content: "Feedback from user goes here", 
    });

    return <FeedbackChannelRow>
        {loading ? 
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: "center", alignItems: "center" }}>
                <CircularProgress size={20} />
            </Box> : 
            <FeedbackChannelRowInner>
                <div>{channel.name}</div>
                <FeedbackChannelRowContent>
                    <IconButton onClick={() => copyToClipboard(jsonObect)} ><CopyIcon/></IconButton>
                    <IconButton onClick={() => removeFeedbackChannel(channel)}><DeleteIcon/> </IconButton>  
                </FeedbackChannelRowContent>
            </FeedbackChannelRowInner>
            }
    </FeedbackChannelRow>
}

const FeedbackChannelRow = styled("div")({
    height: token.size.height.rowDisplay,
    paddingLeft: token.spacing.s300,
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: token.color.border.inactive
})

const FeedbackChannelRowInner = styled("div")({
    display: "flex",
    alignItems: "center",

})

const IconButton = styled("button")({
    padding: "0",
    background: "transparent",
    border: "0",
    outline: "0",
    color: "#fff",
    cursor: "pointer",
    ":hover": {
        color: "#ddd"
    }
});

const FeedbackChannelRowContent = styled("div")({
    textAlign: "right",
    flexGrow: 1
})

const FeedbackChannels = () => {
    const { gameId } = useParams();
    const [ channels, setChannels ] = useState<GameFeedbackChannel[]>([]);
    const [ loading, setLoading ] = useState(true);

    const getChannels = async (token?: string) => {
        setLoading(true);
        try {
            const result = await Api.Game.FeedbackChannelList(gameId!, undefined, undefined);
            setChannels(result.items);
            //setNextToken(result.nextToken);
        } catch(e: any) {
    
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getChannels(undefined);
    }, []);

    const removeFeedbackChannel = async (channelId: string) => {
        await Api.Game.FeedbackChanelRemove(gameId!, channelId);
    }

    const addFeedbackChannel = async (values: { name: string }) => {
        const channel = await Api.Game.FeedbackChannelAdd(gameId!, values.name);

        setChannels((current) => {
            return [ ...current, channel];
        })
    }

    const url = `${publicApi}/postfeedback`

    return <Section header="Channels" collapse>
        <Typography fontSize={token.font.size.small}>
            You can press the copy icon to copy the json blob to your clipboard to use in your game.
        </Typography>
        <Typography fontSize={token.font.size.small}>
            API URL: {url}
        </Typography>
        <Stack spacing={2} marginY={token.spacing.s400}>{channels.map((channel) => {
            return <FeedbackChannel key={channel.id} channel={channel} onRemove={(id) => removeFeedbackChannel(id)} />
        })}</Stack>

        <Box marginTop={token.spacing.s400}>
            <Typography fontSize={24}>Add a new feedback channel</Typography>
            <Typography fontSize={token.font.size.body}>
                A feedback channel a specific stream of feedback items, you may use this for a demo or different versions of your game.
                Once you create a channel you can post from your game using the game-id and channel-id to record feedback directly in your game.
            </Typography>
            <Formik initialValues={{name: ""}} onSubmit={(values) => addFeedbackChannel(values)}>
            {({
                handleSubmit, values
            }) => (<>
                <TextInput
                    label="Name"
                    name="name"
                    type="text"
                    disabled={false}
                />
                <Button onClick={() => handleSubmit()}>Add Channel</Button>
            </>)}
            </Formik>
        </Box>
    </Section>
}

export const FeedbackItems = () => {
    const theme = useTheme();
    const { gameId } = useParams();
    const { confirm } = useConfirmModal();

    const [ items, setItems] = useState<GameFeedback[]>([]);
    const [ loading, setLoading ] = useState(true);
    const [ nextToken, setNextToken ] = useState<string>();

    const getItems = async (token?: string) => {
        setLoading(true);
        try {
            const result = await Api.Game.ListFeedback(gameId!, undefined, undefined);
            setItems(result.items);
            setNextToken(result.nextToken);
        } catch(e: any) {
    
        } finally {
            setLoading(false)
        }
    }

    const loadMore = () => {
        getItems(nextToken);
    }

    const refresh = () => {
        getItems(undefined);
    }

    const removeItem = async (id: string) => {
        const remove = await confirm({
            title: "Delete feedback?",
            message: "Are you sure you want to delete this feedback."
        });

        if(remove) {
            try {
                await Api.Game.RemoveFeedback(gameId!, id);
                setItems((current) => {
                    return current.filter(i => i.id !== id);
                });
            }
            catch( e: any){
                AddAlert({
                    message: "Failed to delete feedback",
                    severity: "error"
                })
            }
        }
    }

    const setFlagged = async (id: string, flagged: boolean) => {
        await Api.Game.EditFeedback(id, flagged);
        //edit locally
        setItems((old) => {
            const newItems = [...old]
            const index = newItems.findIndex((f) => f.id === id);
            newItems[index].flagged = flagged;
            return newItems;
        })
    }

    useEffect(() => {
        getItems(undefined);
    }, []);

    if(loading){
        return <>Loading</>
    }

    return <>
        <ContentContainer>
            <FeedbackChannels />
        </ContentContainer>

        <ContentContainer>
            <Section header="Feedback">
                <Box textAlign={"right"}><FormControlLabel control={<Switch value={false} />} label="Show flagged only" /></Box>
                <Stack spacing={4}>
                    {items.map((feedback) => {
                        return <Box key={feedback.id}>
                            <Typography fontSize={12}>
                                {new Date(feedback.createdAt).toDateString()}
                                <IconButton onClick={() => setFlagged(feedback.id, !feedback.flagged)} ><BookmarkIcon sx={{ fontSize: 14, verticalAlign: "top" }} color={feedback.flagged ? "info" : "disabled"}  /></IconButton>
                                <IconButton onClick={() => removeItem(feedback.id)} ><DeleteIcon sx={{ fontSize: 14, verticalAlign: "top" }}  /></IconButton>
                            </Typography>
                            <Box marginY={1}>{feedback.content}</Box>
                            <Stack direction="row" spacing={1}>
                                {feedback.tags !== undefined && feedback.tags.map((tag) => {
                                    return <Chip key={tag} label={tag} variant="outlined" />;
                                })}
                            </Stack>
                        </Box>
                    })}
                </Stack>
            </Section>
            {nextToken !== undefined && <Button onClick={() => loadMore()}>Load More</Button>}
        </ContentContainer>
    </>
}
