import { useEffect, useState } from "react";
import Page from "../../components/page"
import { Lead } from "@dragonsight/models";
import { Api } from "../../shared/api";
import { Button, CircularProgress, IconButton, LinearProgress, Link, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import AddLeadDialog from "./addLead";
import { Clear, Edit } from "@mui/icons-material";
import EditLeadDialog from "./editLead";
import { AddAlert } from "../../shared/alert";


const LeadRow = (props: {lead: Lead, onDelete: (id: string) => void, onEdit: (lead: Lead) => void}) => {
    const {lead} = props;

    const [deleting, setDeleting] = useState(false);

    const handleDelete = async (id: string) => {
        setDeleting(true);
        try {
            await Api.Crm.Delete(id);
            props.onDelete(id);
        }
        catch(error: any) {

        }
        finally {
            setDeleting(false);
        }
    }

    return <TableRow
        key={lead.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
        <TableCell component="th" scope="row">
            {lead.name}
        </TableCell>
        <TableCell align="right">{lead.company}</TableCell>
        <TableCell align="right">
            {lead.contacts.map((contact) => (<div key={contact.username}>
                {contact.username}
            </div>))}
        </TableCell>
        <TableCell align="right">
            {lead.platforms.map((platform) => (<div key={platform}>
                <Link href={platform}>{platform}</Link>
            </div>))}
        </TableCell>
        {/* <TableCell align="right">{lead.messages.length}</TableCell> */}
        <TableCell align="right">
            {deleting ? <CircularProgress /> :
            <>
                <IconButton color="primary" onClick={() => handleDelete(lead.id)}><Clear /></IconButton>
                <IconButton color="primary" onClick={() => props.onEdit(lead)}><Edit /></IconButton>
             </>}
        </TableCell>
    </TableRow>
}

const LeadTable = (props: {
    leads: Lead[],
    onDelete: (id: string) => void,
    onEdit: (lead: Lead) => void,
    onLoadMore?: () => void}
    ) => {

    return <>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Company</TableCell>
                    <TableCell align="right">Contacts</TableCell>
                    <TableCell align="right">Platforms</TableCell>
                    {/* <TableCell align="right">Messages</TableCell> */}
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {props.leads.map((lead) => (<LeadRow lead={lead} onDelete={props.onDelete} onEdit={props.onEdit} />))}
            </TableBody>
            {props.onLoadMore !== undefined && <div>
                <Button onClick={props.onLoadMore}>Load More</Button>
                </div>}
        </Table>
    </>
}

const CrmPage = () => {
    const [leads, setLeads] = useState<Lead[]>([]);
    const [leadNextToken, setLeadNextToken] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(true);

    const [showNewLeadForm, setShowNewLeadForm] = useState(false);

    const [editLead, setEditLead] = useState<Lead | undefined>(undefined);

    const loadLeads = async (reset: boolean) => {
        try {
            const leadResponse = await Api.Crm.List("", leadNextToken, undefined);
            setLeadNextToken(leadResponse.nextToken);
            if(reset){
                setLeads(leadResponse.items);
            }else{
                setLeads([ ...leads, ...leadResponse.items]);
            }
            setLoading(false);
        } catch (error: any) {
            AddAlert({
                message: "Could not get leads",
                severity: "error"
            });
        }
    }
    
    useEffect(() => {
        loadLeads(true);
    }, [])

    const handleNewLeadButton = () => {
        setShowNewLeadForm(true);
    }

    const handleNewLead = (lead: Lead) => {
        setLeads([ lead, ...leads]);
    }

    const handleDelete = (id: string) => {
        setLeads( leads.filter((value) => value.id !== id));
    }

    const handleEdit = (lead: Lead) => {
        setLeads( leads.map((l) => l.id === lead.id ? lead : l));
    }

    const content = () => {
        if(loading){
            return <LinearProgress />
        }
        return <>
            <div><Button onClick={handleNewLeadButton}>Add Lead</Button></div>
            <LeadTable
                leads={leads}
                onDelete={handleDelete}
                onEdit={(lead) => setEditLead(lead)}
                onLoadMore={leadNextToken === undefined ? undefined : () => loadLeads(false)}
                />
            </>
    }

    return <Page title="Crm" breadcrumbs={[
        { label: "Home", to: "/"},
        { label: "Crm" }
    ]}>
        <AddLeadDialog show={showNewLeadForm} onClose={() => setShowNewLeadForm(false)} onNewLeadAdded={handleNewLead} />
        <EditLeadDialog show={editLead !== undefined} onClose={() => setEditLead(undefined)} onLeadEdit={handleEdit} lead={editLead} />
        {content()}
    </Page>
}

export default CrmPage