import { Typography, Container, Breadcrumbs, Button, Link, LinkProps } from "@mui/material";
import { MouseEventHandler, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface PageProps {
    title: string;
    children?: ReactNode;
    breadcrumbs?: {
        label: string,
        to?: string,
    }[]
}

const NewLink = (props: { to: string, children?: ReactNode} & LinkProps) => {
    const navigate = useNavigate();
    return <Link {...props} onClick={(event) => {
        event.preventDefault();
        navigate(props.to);
    }}>{props.children}</Link>
}

const Page = (props: PageProps) => {
    const { title, children, breadcrumbs } = props;
    const navigate = useNavigate();

    return <>
        { breadcrumbs !== undefined && <Breadcrumbs aria-label="breadcrumb">
            {breadcrumbs.map((item) => {
                if(item.to === undefined) {
                    return <Typography key={item.label} color="text.primary">{item.label}</Typography>
                }
                return <NewLink key={item.label} underline="hover" href={item.to} to={item.to!}>{item.label}</NewLink>;
            })}
            </Breadcrumbs>}
        <Typography
          component="h1"
          variant="h3"
          align="left"
          color="text.primary"
          gutterBottom
        >
          {title}
        </Typography>

        <Container maxWidth="lg" component="main" sx={{ mt: 6}}>
            {children}
        </Container>
    </>
}

export default Page;