import { Avatar, Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import * as Auth from '../../shared/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { TextInput } from '../../components/form';
import { AddAlert } from '../../shared/alert';

interface ForgotPageProps {
  onSwitchToSignIn: () => void;
}

const ForgotPage = (props: ForgotPageProps) => {
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async (values: {email: string}) => {
      setSubmitting(true);

      try {
          await Auth.forgotPassword(values.email);
      } catch(e: any){
          AddAlert({
            message: e.message,
            severity: "error"
          })
      }
      finally {
          setSubmitting(false);
      }
    }

    return <>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot password
          </Typography>
          <Formik initialValues={{ email: "", password: "", accountName: "" }}
              onSubmit={(values) => onSubmit(values)}>
              {({
              handleSubmit,
            }) => (
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput
                  label="Email Address"
                  name="email"
                  type="email"
                  disabled={submitting}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={submitting}
              sx={{ mt: 3, mb: 2 }}
            >
              Reset password
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                
                <Button type='button' variant='text' onClick={() => props.onSwitchToSignIn()}>
                  Sign in
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
          </Formik>
        </Box>
    </>
}

export default ForgotPage;